<template>
    <el-container class="app-wrapper mobile" :lang="$i18n.locale">
        <el-header class="header" height="70">
            <div class="header-bar">
                <!-- 公司logo与公司名称 -->
                <router-link to="/" class="logo">
                    <div class="image">
                        <img src="@/assets/logo.png" />
                    </div>
                    <div class="title">
                        {{$t('layout.companyName')}}
                    </div>
                </router-link>

                <!-- 操作栏，搜索、切换中英文 -->
                <div class="oper">
                    <div class="search" @click="searchVisible = true">
                        <span class="icon-search"></span>
                    </div>
                    <div class="separator"></div>
                    <el-popover trigger="click" width="20">
                        <div slot="reference" class="lang" @click="langSelectVisible = true">
                            <!-- 中英文切换 -->
                            <span v-if="$i18n.locale === 'zh-CN'" class="icon-lang-chinese"></span>
                            <span v-if="$i18n.locale === 'en-US'" class="icon-lang-english"></span>
                        </div>
                        <div v-if="langSelectVisible" class="lang-select">
                            <ul class="reset text-xs">
                                <li @click="setLang('zh-CN')" class="flex align-center justify-end pointer">
                                    <span class="text-xxxs margin-right-xs">中文</span>
                                    <span class="icon-lang-chinese"></span>
                                </li>
                                <li @click="setLang('en-US')" class="flex align-center justify-end pointer">
                                    <span class="text-xxxs margin-right-xs">English</span>
                                    <span class="icon-lang-english"></span>
                                </li>
                            </ul>
                        </div>
                    </el-popover>
                </div>

                <!-- 搜索框 -->
                <div :class="!searchVisible ? 'search-bar hide' : 'search-bar show'">
                    <el-input v-model="searchKeyword" :placeholder="$t('layout.search')"
                              :autofocus="searchVisible" maxlength="20"
                              @keyup.enter.native="search">
                        <span slot="suffix" @click="search" class="icon-search"></span>
                    </el-input>
                    <span class="icon-close" @click="searchVisible = false"></span>
                </div>

            </div>
        </el-header>

        <!-- 搜索遮罩 -->
        <transition name="el-fade-in-linear">
            <div v-show="searchVisible" @click="searchVisible = false"
                 @touchmove.prevent @mousewheel.prevent class="search-mask"></div>
        </transition>

        <!-- 路由内容 -->
        <el-main class="main">
            <router-view></router-view>
        </el-main>

        <el-footer class="footer">
            <!-- 菜单信息 -->
            <div class="info-block">
                <div class="info">
                    <div class="info-title">{{$t('layout.aboutHaoKai')}}</div>
                    <router-link to="/company/profile" class="info-line">{{$t('layout.companyProfile')}}</router-link>
                    <router-link to="/company/culture" class="info-line">{{$t('layout.companyCulture')}}</router-link>
                </div>
                <div class="info" style="padding-left: 10px">
                    <div class="info-title">{{$t('layout.news')}}</div>
                    <router-link :to="'/news/news-list?type=' + $t('layout.companyNews')" class="info-line">{{$t('layout.companyNews')}}</router-link>
                    <router-link :to="'/news/news-list?type=' + $t('layout.industryNews')" class="info-line">{{$t('layout.industryNews')}}</router-link>
                </div>
                <div class="info">
                    <div class="info-title">{{$t('layout.productsCenter')}}</div>
                    <router-link v-for="p in footerItems" v-bind:key="p.itemId" class="info-line text-trunc"
                                 :to="`/product/product-detail?productId=${p.itemId}&product=${p.name}`">
                        {{$i18n.locale === 'zh-CN' ? p.zhName : p.enName}}
                    </router-link>
                </div>
                <div class="info" style="padding-left: 10px">
                    <div class="info-title">{{$t('layout.companyPhotos')}}</div>
                    <router-link :to="'/photos?type=' + $t('layout.companyHonor')" class="info-line">{{$t('layout.companyHonor')}}</router-link>
                    <router-link :to="'/photos?type=' + $t('layout.companyEnvironment')" class="info-line">{{$t('layout.companyEnvironment')}}</router-link>
                </div>
            </div>
            <div class="info-block">
                <div class="info">
                    <div class="info-title">{{$t('layout.contactUs')}}</div>
                    <div class="info-line">
                        <div class="info-label">{{$t('layout.address')}}：</div>
                        <div class="info-text">{{$t('layout.addressValue')}}</div>
                    </div>
                    <div class="info-line">
                        <div class="info-label">{{$t('layout.contact')}}：</div>
                        <div class="info-text">{{$t('layout.contactValue')}}</div>
                    </div>
                    <div class="info-line">
                        <div class="info-label">{{$t('layout.cellTel')}}：</div>
                        <div class="info-text">{{$t('layout.cellTelValue')}}</div>
                    </div>
                    <div class="info-line">
                        <div class="info-label">{{$t('layout.tel')}}：</div>
                        <div class="info-text">{{$t('layout.telValue')}}</div>
                    </div>
                    <div class="info-line">
                        <div class="info-label">{{$t('layout.fax')}}：</div>
                        <div class="info-text">{{$t('layout.faxValue')}}</div>
                    </div>
                    <div class="info-line">
                        <div class="info-label">{{$t('layout.email')}}：</div>
                        <div class="info-text">{{$t('layout.emailValue')}}</div>
                    </div>
                    <div class="info-line">
                        <div class="info-label">{{$t('layout.zip')}}：</div>
                        <div class="info-text">{{$t('layout.zipCode')}}</div>
                    </div>
                </div>
            </div>
            <!-- 版权信息 -->
            <div class="copy-right-block">
                <div class="copy-right">
                    <p>CopyRight 2017 All Right Reserved</p>
                    <a href="http://beian.miit.gov.cn">浙ICP备12031803号-1</a>
                </div>
                <div class="copy-right">{{$t('layout.address')}}：{{$t('layout.addressValue')}} {{$t('layout.cellTel')}}：{{$t('layout.cellTelValue')}} {{$t('layout.fax')}}：{{$t('layout.faxValue')}}</div>
                <div class="copy-right">{{$t('layout.email')}}：{{$t('layout.emailValue')}}</div>
            </div>
        </el-footer>
    </el-container>
</template>


<script>
    export default {

      props: ['items'],

      data() {
        return {
          langSelectVisible: false,
          searchVisible: false,

          searchKeyword: '',
        };
      },

      computed: {
        footerItems() {
          let items = this.items;
          if (items && items.length > 6) {
            items = items.splice(0, 6);
          }
          if (items) {
            items.forEach(i => {
              i.name = this.$i18n.locale === 'zh-CN' ? i.zhName : i.enName;
            });
          }
          return items;
        },
      },

      created() {
        // 从storage中读取上次选中的语言，如果没有就根据浏览器设置的语言加载
        let locale = localStorage.getItem('locale') || navigator.language;
        if (locale !== 'zh-CN') {
          locale = 'en-US';
        }
        this.$i18n.locale = locale;
      },

      methods: {

        search() {
          if (!this.searchKeyword || this.searchKeyword === '') {
            this.$message.warning('请输入搜索关键字！')
            return;
          }

          this.searchVisible = false;
          this.$router.push({
            path: '/product/product-center',
            query: {
              keyword: this.searchKeyword
            }
          });
        },

        setLang(lang) {
          localStorage.setItem('locale', lang);
          location.reload();
        },
      },

    }
</script>

<style lang="scss">

    .mobile {
        .header {
            height: 70px;
            padding: 0;
            background-color: var(--white);
            box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
            z-index: 10;

            .header-bar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                padding: 0 20px;
                position: relative;

                .logo {
                    display: flex;
                    align-items: center;

                    .image {
                        margin-right: 10px;

                        img {
                            max-width: 80px;
                            max-height: 40px;
                        }
                    }

                    .title {
                        font-size: 16px;
                        font-weight: 700;
                    }
                }

                .oper {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-size: 20px;

                    .search,.lang {
                        cursor: pointer;
                    }

                    .separator {
                        width: 1px;
                        height: 30px;
                        margin: 0 10px;
                        background-color: rgba(1,1,1, .3);
                    }

                    .lang {
                        position: relative;
                    }

                    .lang-select {
                        font-size: 20px;
                        position: absolute;
                        right: -50%;
                        bottom: -90px;
                        background-color: var(--white);

                        [class*=icon-lang] {
                            display: inline-block;
                            margin: 10px;
                        }
                    }
                }

                .search-bar {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: var(--white);

                    -webkit-transition: all linear .1s;
                    -moz-transition: all linear .1s;
                    -ms-transition: all linear .1s;
                    -o-transition: all linear .1s;
                    transition: all linear .1s;
                    transform-origin: right;

                    &.show {
                        opacity: 1;
                        transform: scale(1);
                    }
                    &.hide {
                        opacity: 0;
                        transform: scale(0);
                    }

                    .el-input {
                        margin-left: 20px;

                        .icon-search {
                            font-size: 16px;
                            padding: 10px;
                            display: inline-block;
                            cursor: pointer;
                        }
                    }

                    .icon-close {
                        cursor: pointer;
                        padding: 0 10px;
                        font-size: 20px;
                    }
                }

            }
        }

        .search-mask {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 6;
            background-color: rgba(1,1,1, .3);
        }

        .main {
            padding: 0;
            z-index: 5;
        }

        .footer {
            padding: 0 20px;
            background-color: #f0f0f0;

            .info-block {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .info {
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    .info-title {
                        margin-bottom: 10px;
                        font-size: 20px;
                        font-weight: 700;
                    }
                    .info-line {
                        height: 24px;
                        line-height: 24px;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }

            .info-block:nth-child(2) {
                .info {
                    width: 100%;

                    .info-line {
                        display: flex;
                        height: auto;

                        .info-label {
                            min-width: 60px;
                        }
                        .info-text {
                            flex: 1;
                        }
                    }
                }
            }

            .copy-right-block {
                margin: 40px 0 20px 0;
                text-align: center;

                .copy-right:first-child {
                    margin-bottom: 10px;
                }
            }
        }

    }

    .el-popper {
        min-width: 110px !important;
    }


</style>
